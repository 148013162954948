
import {Component, Vue} from 'vue-property-decorator';
import AppStore from '@/components/AppStore';
import Http from '@/Http';

@Component({})
export default class Auth extends Vue {

  phoneNumber = '+7';
  password = '';

  errorAlertText = '';

  formatPhoneNumber() {
    if (this.phoneNumber.length <= 1) {
      this.phoneNumber = '+7';
    }
    if (this.phoneNumber === '+7 7' || this.phoneNumber === '+7 8'
        || this.phoneNumber === '+77' || this.phoneNumber === '+78') {
      this.phoneNumber = '+7';
    }
    this.errorAlertText = '';

  }

  loginIn() {

    AppStore.showLoader();
    Http.post('/login/in',
        {phoneNumber: this.phoneNumber, password: this.password})
        .then((value: any) => {
          localStorage.setItem('token', value.token);
          document.location.href = '/';
        }).finally(() => {
      AppStore.hideLoader();
    }).catch((HttpError) => {
      switch (HttpError.error) {
        case 'phone_or_password_invalid':
          this.errorAlertText = 'Введен неверный логин или пароль';
          (this.$refs.password as HTMLInputElement).focus();
          break;
        default:
          Http.systemError(HttpError.error);
      }
    });

  }

  created() {
    AppStore.showLoader();
    Http.get('/login/check',).then((value: any) => {

    }).finally(() => {
      AppStore.hideLoader();
    }).catch((HttpError) => {
      switch (HttpError.error) {
        case 'already_logged':
          this.$router.push('/');
          break;
        default:
          Http.systemError(HttpError.error);
      }
    });

  }
}
